import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  MergeLinkCustomizationStatus,
  OrganizationCustomization,
} from "../link/context/CustomizationContext";
import { Button, ButtonVariant, Toggle, Tooltip } from "@merge-api/merge-javascript-shared";
import { fetchWithAuth, publishCustomizableLinkChanges } from "../../../api-client/APIClient";
import ConfigurationSettingsCard from "../link/helpers/ConfigurationSettingsCard";
import { Eye } from "lucide-react";

type ConfigurationSelectiveSyncCardProps = {
  previewLinkEnabled: boolean;
  setConditionPresetPreviewLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const ConfigurationSelectiveSyncCard = ({
  previewLinkEnabled,
  setConditionPresetPreviewLinkDialogOpen,
}: ConfigurationSelectiveSyncCardProps) => {
  // get the existing configs based on org
  // set state
  const [organizationCustomization, setOrganizationCustomization] =
    useState<OrganizationCustomization | null>(null);

  useEffect(() => {
    fetchWithAuth({
      path: `integrations/customizable-link-settings`,
      method: "GET",
      onResponse: (customizationSettings) => {
        setOrganizationCustomization(customizationSettings.organization_customization);
      },
    });
  }, []);

  const showSelectiveSync = organizationCustomization?.show_selective_sync_screen ?? false;

  const updateEnableSelectiveSync = (newValue: boolean) => {
    let updatedOrganizationCustomization;
    // either create new org customization value or update existing one
    if (organizationCustomization != undefined && organizationCustomization != null) {
      updatedOrganizationCustomization = {
        ...organizationCustomization,
        show_selective_sync_screen: newValue,
      };
    } else {
      updatedOrganizationCustomization = {
        status: "PUBLISHED" as MergeLinkCustomizationStatus,
        show_selective_sync_screen: newValue,
      };
    }
    setOrganizationCustomization(updatedOrganizationCustomization);
    publishCustomizableLinkChanges({
      status: "PUBLISHED",
      organization_customization_data: updatedOrganizationCustomization,
      integration_customizations_data: null,
    });
  };

  return (
    <>
      <ConfigurationSettingsCard
        title="Show in Merge Link"
        rightHandContent={
          <>
            <Tooltip
              title={
                showSelectiveSync
                  ? previewLinkEnabled
                    ? ""
                    : "No filters to preview"
                  : "Selective Sync is disabled in Merge Link"
              }
            >
              <Button
                size="sm"
                variant={ButtonVariant.TertiaryWhite}
                fullWidth
                leftIcon={<Eye className="h-3, w-3"></Eye>}
                onClick={() => setConditionPresetPreviewLinkDialogOpen(true)}
                disabled={!previewLinkEnabled || !showSelectiveSync}
              >
                Preview
              </Button>
            </Tooltip>
          </>
        }
      >
        <div className="flex flex-row justify-between">
          <span>
            Show preset filters in Merge Link to allow your users to select what data is synced from
            third-party platforms
          </span>
          <Toggle
            checked={showSelectiveSync}
            label={showSelectiveSync ? "Show in Link" : " "}
            onChange={() => {
              updateEnableSelectiveSync(!showSelectiveSync);
            }}
          />
        </div>
      </ConfigurationSettingsCard>
    </>
  );
};

export default ConfigurationSelectiveSyncCard;
