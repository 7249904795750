import { useParams } from "react-router-dom";
import { APICategory, ConditionPreset, ConditionPresetMeta } from "../../../../models/Entities";
import { useRequest } from "../../../shared-components/hooks/useRequest";

// constants
const GET_CONDITION_PRESET_PATH = "integrations/selective-sync/condition-presets";
const GET_CONDITION_PRESET_META_PATH = "integrations/selective-sync/condition-presets/meta";

type useLoadConditionPresetDataProps = {
  categoryName?: APICategory;
};

const useLoadConditionPresetData = ({ categoryName }: useLoadConditionPresetDataProps) => {
  let { category } = useParams<{ category: APICategory }>();

  if (categoryName !== null && categoryName !== undefined) {
    category = categoryName;
  }

  const {
    loading: conditionPresetsLoading,
    data: remoteConditionPresets,
    refetch: refetchConditionPresets,
  } = useRequest<ConditionPreset[]>({
    path: `${GET_CONDITION_PRESET_PATH}?category=${category}`,
    errorText: "Error loading condition presets",
  });
  const {
    loading: conditionPresetMetaLoading,
    data: conditionPresetMeta,
    refetch: refetchConditionPresetMeta,
  } = useRequest<ConditionPresetMeta>({
    path: `${GET_CONDITION_PRESET_META_PATH}?category=${category}`,
    errorText: "Error loading condition presets meta",
  });

  return {
    remoteConditionPresets,
    conditionPresetMeta,
    loading: conditionPresetsLoading || conditionPresetMetaLoading,
    refetch: () => {
      refetchConditionPresets();
      refetchConditionPresetMeta();
    },
  };
};

export default useLoadConditionPresetData;
