import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import useAppContext from "../../context/useAppContext";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { Button, ButtonVariant, Toggle, Tooltip } from "@merge-api/merge-javascript-shared";
import ConfigurationSettingsCard from "../link/helpers/ConfigurationSettingsCard";
import { Eye } from "lucide-react";

type ConfigurationSelectiveSyncSettingsPageProps = {
  previewLinkEnabled: boolean;
  setConditionPresetPreviewLinkDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const ConfigurationSelectiveSyncSettingsPage = ({
  previewLinkEnabled,
  setConditionPresetPreviewLinkDialogOpen,
}: ConfigurationSelectiveSyncSettingsPageProps) => {
  const { user, setUser } = useAppContext();
  const [showSelectiveSync, setShowSelectiveSync] = useState<boolean | undefined>(
    user.organization?.show_selective_sync,
  );

  const updateEnableOrganizationSelectiveSync = useCallback(
    ({ showSelectiveSync }: { showSelectiveSync?: boolean }) => {
      fetchWithAuth({
        path: "/organizations/toggle-show-selective-sync",
        method: "PATCH",
        body: {
          show_selective_sync: showSelectiveSync,
        },
        onResponse: (data) => {
          fetchCurrentUser(setUser);
          setShowSelectiveSync(data.show_selective_sync);
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  return (
    <ConfigurationSettingsCard
      title="Show in Merge Link"
      rightHandContent={
        <>
          <Tooltip
            title={
              showSelectiveSync
                ? previewLinkEnabled
                  ? ""
                  : "No filters to preview"
                : "Selective Sync is disabled in Merge Link"
            }
          >
            <Button
              size="sm"
              variant={ButtonVariant.TertiaryWhite}
              fullWidth
              leftIcon={<Eye className="h-3, w-3"></Eye>}
              onClick={() => setConditionPresetPreviewLinkDialogOpen(true)}
              disabled={!previewLinkEnabled || !showSelectiveSync}
            >
              Preview
            </Button>
          </Tooltip>
        </>
      }
    >
      <div className="flex flex-row justify-between">
        <span>
          Show preset filters in Merge Link to allow your users to select what data is synced from
          third-party platforms
        </span>
        <Toggle
          checked={showSelectiveSync}
          label={showSelectiveSync ? "Show in Link" : " "}
          onChange={(newIsEnabled) => {
            updateEnableOrganizationSelectiveSync({
              showSelectiveSync: newIsEnabled,
            });
          }}
        />
      </div>
    </ConfigurationSettingsCard>
  );
};

export default ConfigurationSelectiveSyncSettingsPage;
