import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { APICategory, ConditionPreset } from "../../../../../models/Entities";
import { Badge, Select } from "@merge-api/merge-javascript-shared";
import size from "lodash/size";

export type IntegrationWithConditionPresets = {
  conditionPresets: Partial<ConditionPreset>[];
  id?: string | undefined;
  name?: string | undefined;
  square_image?: string | undefined;
};

type IntegrationSelectProps = {
  className?: string;
  integration?: IntegrationWithConditionPresets;
  setIntegration: Dispatch<SetStateAction<IntegrationWithConditionPresets | undefined>>;
  setShowLinkCardData: Dispatch<SetStateAction<boolean>>;
  integrations: IntegrationWithConditionPresets[];
  categoryName?: APICategory;
};

const IntegrationSelect = ({
  className,
  integration,
  setIntegration,
  setShowLinkCardData,
  integrations,
  categoryName,
}: IntegrationSelectProps) => {
  const handleIntegrationChange = (integration: IntegrationWithConditionPresets | null) => {
    if (integration) {
      setShowLinkCardData(true);
      setIntegration(integration);
    }
  };
  return (
    <div className={clsx("", className)}>
      <Select
        options={integrations}
        value={integration}
        getOptionLabel={({ name }) => name!}
        clearable={false}
        onChange={(_, integration) => handleIntegrationChange(integration)}
        renderOption={({ name, square_image, conditionPresets }) => {
          return (
            <div className="flex flex-row w-full items-center justify-between">
              <div>
                <img
                  src={square_image}
                  alt={name}
                  className="w-4 h-4 object-cover rounded-full bg-white mr-1.5"
                />
                {name}
              </div>
              <Badge color="blue" className="mr-3">
                {size(conditionPresets)} filter{size(conditionPresets) > 1 && "s"}
              </Badge>
            </div>
          );
        }}
        disabled={!categoryName}
      />
    </div>
  );
};

export default IntegrationSelect;
