import React, { useState } from "react";
import {
  APICategory,
  Spinner,
  Text,
  displayNameForAPICategory,
} from "@merge-api/merge-javascript-shared";
import useManageConditionPresets from "./hooks/useManageConditionPresets";
import ConditionPresetsList from "./ConditionPresetsList";
import ConditionPresetConfirmDiscardChangesDialog from "./ConditionPresetConfirmDiscardChangesDialog";
import ConditionPresetHeader from "./ConditionPresetHeader";
import ConditionPresetPublishChangesDialog from "./ConditionPresetPublishChangesDialog";
import ConditionPresetPreviewLinkDialog from "./ConditionPresetPreviewLinkDialog";
import useLoadConditionPresetData from "./hooks/useLoadConditionPresetData";
import useConditionPresetIntegrations from "./ConditionPresetPreviewLinkDialog/hooks/useConditionPresetIntegrations";
import { useParams } from "react-router-dom";
import useAppContext from "../../context/useAppContext";

const ConfigurationSelectiveSync = () => {
  const { user } = useAppContext();

  // state
  const [publishChangesDialogOpen, setPublishChangesDialogOpen] = useState(false);
  const [conditionPresetPreviewLinkDialogOpen, setConditionPresetPreviewLinkDialogOpen] =
    useState(false);

  // hooks
  const { category } = useParams<{ category: APICategory }>();
  const { loading, refetch, remoteConditionPresets, conditionPresetMeta } =
    useLoadConditionPresetData({});
  const {
    conditionPresets,
    conditionPresetErrors,
    onConditionPresetChange,
    onConditionPresetDelete,
    onConditionPresetAdd,
    publishChanges,
    changes,
    publishing,
    hasChanges,
    validateChanges,
  } = useManageConditionPresets({
    remoteConditionPresets,
    refetch: () => {
      refetch();
      setPublishChangesDialogOpen(false);
    },
  });
  const { previewLinkEnabled, hasMappedFiltersForCategory } = useConditionPresetIntegrations({
    conditionPresets,
    conditionPresetMeta,
  });

  // event handlers
  const onPublishChangesClick = () => {
    const hasErrors = validateChanges();

    if (hasErrors) return;
    setPublishChangesDialogOpen(true);
  };

  const showFileStorageSelectiveSync =
    category === APICategory.filestorage && user.is_file_storage_selective_sync_enabled;

  return (
    <>
      <div className="flex flex-col pb-5">
        <ConditionPresetHeader
          hasChanges={hasChanges}
          previewLinkEnabled={previewLinkEnabled}
          onPublishChangesClick={onPublishChangesClick}
          setConditionPresetPreviewLinkDialogOpen={setConditionPresetPreviewLinkDialogOpen}
        />
        {showFileStorageSelectiveSync ? (
          <div className="mt-8 flex justify-center flex-col items-center">
            <Text variant="h4" className="flex text-gray-40">
              No filters are available for {displayNameForAPICategory(category)}
            </Text>
            <Text variant="sm" className="flex text-gray-40 font-semibold">
              Selective Sync filters for {displayNameForAPICategory(category)} integrations can only
              be set by your end users in Merge Link
            </Text>
          </div>
        ) : loading || !conditionPresetMeta ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : hasMappedFiltersForCategory ? (
          <ConditionPresetsList
            conditionPresets={conditionPresets}
            conditionPresetErrors={conditionPresetErrors}
            conditionPresetMeta={conditionPresetMeta}
            onConditionPresetChange={onConditionPresetChange}
            onConditionPresetDelete={onConditionPresetDelete}
            onConditionPresetAdd={onConditionPresetAdd}
          />
        ) : (
          <div className="mt-8 flex justify-center">
            <Text variant="h4" className="text-gray-40">
              No filters are currently available for {displayNameForAPICategory(category)}
            </Text>
          </div>
        )}
      </div>

      <ConditionPresetConfirmDiscardChangesDialog hasChanges={hasChanges} />

      <ConditionPresetPublishChangesDialog
        changes={changes}
        open={publishChangesDialogOpen}
        onClose={() => setPublishChangesDialogOpen(false)}
        publishChanges={publishChanges}
        publishing={publishing}
      />

      <ConditionPresetPreviewLinkDialog
        open={conditionPresetPreviewLinkDialogOpen}
        onClose={() => setConditionPresetPreviewLinkDialogOpen(false)}
      />
    </>
  );
};

export default ConfigurationSelectiveSync;
