import clsx from "clsx";
import React, { Dispatch, SetStateAction } from "react";
import { Select, APICategory, displayNameForAPICategory } from "@merge-api/merge-javascript-shared";

type CategorySelectProps = {
  className?: string;
  categories: APICategory[];
  setCategoryName: Dispatch<SetStateAction<APICategory | undefined>>;
  setShowLinkCardData: Dispatch<SetStateAction<boolean>>;
  category?: APICategory;
};

const CategorySelect = ({
  className,
  categories,
  setCategoryName,
  setShowLinkCardData,
  category,
}: CategorySelectProps) => {
  const handleCategoryChange = (newCategory: APICategory | null) => {
    setShowLinkCardData(false);
    if (newCategory) {
      setCategoryName(newCategory);
    }
  };
  return (
    <div className={clsx("", className)}>
      <Select
        className="max-w-[192px] bg-white"
        options={categories}
        renderOption={(category: APICategory) => <>{displayNameForAPICategory(category)}</>}
        onChange={(_, category) => handleCategoryChange(category)}
        value={category}
        clearable={false}
      />
    </div>
  );
};

export default CategorySelect;
