import React, { useEffect, useState } from "react";
import { APICategory, Dialog } from "@merge-api/merge-javascript-shared";
import { ConditionPreset, ConditionPresetMeta } from "../../../../models/Entities";
import IntegrationSelect, { IntegrationWithConditionPresets } from "./components/IntegrationSelect";
import LinkCard from "./components/LinkCard";
import useConditionPresetIntegrations from "./hooks/useConditionPresetIntegrations";
import CategorySelect from "./components/CategorySelect";
import INTEGRATION_CATEGORY_LIST from "../../../../models/Helpers";
import useLoadConditionPresetData from "../hooks/useLoadConditionPresetData";

type ConditionPresetPreviewLinkDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ConditionPresetPreviewLinkDialog = ({
  open,
  onClose,
}: ConditionPresetPreviewLinkDialogProps) => {
  // state
  const [integration, setIntegration] = useState<IntegrationWithConditionPresets>();
  const [categoryName, setCategoryName] = useState<APICategory>();
  const [showLinkCardData, setShowLinkCardData] = useState<boolean>(false);
  const { remoteConditionPresets, conditionPresetMeta } = useLoadConditionPresetData({
    categoryName,
  });
  const conditionPresets: Partial<ConditionPreset>[] = remoteConditionPresets || [];
  const { integrations } = useConditionPresetIntegrations({
    conditionPresets,
    conditionPresetMeta,
  });

  // effects
  // reset to not have any selected integrations when mapped
  useEffect(() => {
    !open && setTimeout(() => setIntegration(undefined), 300);
  }, [open]);

  return (
    <Dialog
      title="Preview Merge Link"
      variant="md"
      className="w-[423px]"
      open={open}
      onClose={onClose}
      footerButtonsHidden
    >
      <div>
        <div className="flex">
          <div className="w-1/3 pr-2">
            <CategorySelect
              className="mb-5"
              categories={INTEGRATION_CATEGORY_LIST}
              setCategoryName={setCategoryName}
              setShowLinkCardData={setShowLinkCardData}
              category={categoryName}
            />
          </div>
          <div className="w-2/3 pl-2">
            <IntegrationSelect
              className="mb-5"
              integration={integration}
              setIntegration={setIntegration}
              setShowLinkCardData={setShowLinkCardData}
              integrations={integrations}
              categoryName={categoryName}
            />
          </div>
        </div>
        <LinkCard
          conditionPresets={integration?.conditionPresets}
          showLinkCardData={showLinkCardData}
        />
      </div>
    </Dialog>
  );
};

export default ConditionPresetPreviewLinkDialog;
